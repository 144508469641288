import React, { useState, useEffect } from "react";
import OpenAI from 'openai';

function Cooking() {

    const openai = new OpenAI({
        apiKey: "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",
        dangerouslyAllowBrowser: true
      });
    
    const [prompt, setPrompt] = useState("");
    const [apiResponse, setApiResponse] = useState("");
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const result = await openai.chat.completions.create({
                model: "gpt-4",
                messages: [{ role: 'user', content: 'Say this is a information about test' }],
                stream: true,
            });

            for await (const chunk of result) {
                console.log(chunk.choices[0]?.delta?.content || "");
            }

            console.log("response", result);
            //setApiResponse(result.choices[0]?.delta?.content);
        } catch (e) {
            console.log(e);
            setApiResponse("Something is going wrong, Please try again.");
        }
    setLoading(false);
    };

    return (
        <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: '100vh',
        }}
      >
        <form onSubmit={handleSubmit}>
          <textarea
            type="text"
            value={prompt}
            placeholder="Please ask to openai"
            onChange={(e) => setPrompt(e.target.value)}
          ></textarea>
          <button
            disabled={loading || prompt.length === 0}
            type="submit"
          >
            {loading ? "Generating..." : "Generate"}
          </button>
        </form>
      </div>
      {apiResponse && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <pre>
            <strong>API response:</strong>
            {apiResponse}
          </pre>
        </div>
      )}
    </>
    );

}

export default Cooking;