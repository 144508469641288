import React from "react";

function Acheivements() {
    return (
        <div class="uk-container">
            <br></br>
            <br></br>
            <div class="uk-child-width-expand@m uk-text-center uk-grid">
                <div>
                    <div class="uk-card uk-card-default uk-card-hover">
                        <div class="uk-card-media-top">
                            <img src="/Acheivements/ckad_from_cncfsite.png" alt="" style={{width: "30%", maxWidth: "25%"}}/>
                        </div>
                        <div class="uk-card-body">
                            <h3><a class="uk-button-text" href="https://www.credly.com/badges/7f8cb5f4-e5ee-4432-9732-487b568186fa/linked_in_profile" target="_blank" style={{textDecoration: "none"}} rel="noopener noreferrer">Certified Kubernetes Application Developer</a></h3>
                            <h5 class="uk-margin-remove">December 2023</h5>
                        </div>
                    </div>
                </div>
                <div class="uk-hidden@m">
                <br></br>
                </div>
                <div>
                    <div class="uk-card uk-card-default uk-card-hover">
                        <div class="uk-card-media-top">
                            <img src="/Acheivements/aws_solution_architect.png" alt="" style={{width: "30%", maxWidth: "25%"}}/>
                        </div>
                        <div class="uk-card-body">
                            <h3><a class="uk-button-text" href="https://www.credly.com/badges/3ea340ca-fc53-4e9b-aad7-654e9261d07e/linked_in_profile" target="_blank" style={{textDecoration: "none"}} rel="noopener noreferrer">AWS Certified Solutions Architect - Associate</a></h3>
                            <h5 class="uk-margin-remove">April 2023</h5>
                        </div>
                    </div>
                </div>
            </div>
            <div class="uk-child-width-expand@m uk-text-center uk-grid">
                <div>
                    <div class="uk-card uk-card-default uk-card-hover">
                        <div class="uk-card-media-top">
                            <img src="/Acheivements/blockchain_expert.png" alt="" style={{width: "30%", maxWidth: "25%"}}/>
                        </div>
                        <div class="uk-card-body">
                            <h3><a class="uk-button-text" href="https://www.credential.net/9c343a4b-0c6c-483a-bace-cd824eec9ef4" target="_blank" style={{textDecoration: "none"}} rel="noopener noreferrer">Certified Blockchain Expert</a></h3>
                            <h5 class="uk-margin-remove">May 2022</h5>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="uk-card uk-card-default uk-card-hover">
                        <div class="uk-card-media-top">
                            <img src="/Acheivements/solidity_developer.png" alt="" style={{width: "30%", maxWidth: "25%"}}/>
                        </div>
                        <div class="uk-card-body">
                            <h3><a class="uk-button-text" href="https://www.credential.net/a55cfb3c-9205-4af2-b64f-52d9b6ade25a" target="_blank" style={{textDecoration: "none"}} rel="noopener noreferrer">Certified Solidity Developer</a></h3>
                            <h5 class="uk-margin-remove">Nov 2021</h5>
                        </div>
                    </div>
                </div>
            </div>
            <div class="uk-child-width-expand@m uk-text-center uk-grid">
                <div>
                    <div class="uk-card uk-card-default uk-card-hover">
                        <div class="uk-card-media-top">
                            <img src="/Acheivements/telc.png" alt="" style={{width: "30%", maxWidth: "25%"}}/>
                        </div>
                        <div class="uk-card-body">
                            <h3><a class="uk-button-text" href="https://www.telc.net/sprachpruefungen/deutsch/zertifikat-deutsch-telc-deutsch-b1/" target="_blank" style={{textDecoration: "none"}} rel="noopener noreferrer">Telc B1 Deutsch</a></h3>
                            <h5 class="uk-margin-remove"></h5>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="uk-card uk-card-default uk-card-hover">
                        <div class="uk-card-media-top">
                            <img src="/Acheivements/fh_kiel.webp" alt="" style={{width: "42%", maxWidth: "45%"}}/>
                        </div>
                        <div class="uk-card-body">
                            <h3><a class="uk-button-text" href="https://www.fh-kiel.de/studium/studienangebot/computer-science/" target="_blank" style={{textDecoration: "none"}} rel="noopener noreferrer">Masters - Information Engineering</a></h3>
                            <h5 class="uk-margin-remove">Oct 2015 - Feb 2019</h5>
                        </div>
                    </div>
                </div>
            </div>
            <br></br>
            <br></br>
        </div>
    );
}

export default Acheivements;